import { css } from "styled-components";

export type ButtonSize = "small" | "medium" | "large";

const padding: { [K in ButtonSize]: { v: string; h: string } } = {
  small: { v: "", h: "" },
  medium: { v: "8px", h: "50px" },
  large: { v: "12px", h: "68px" },
};

export interface ButtonMetrics {
  size: ButtonSize;
  width?: string;
}

export const metrics = (metrics: ButtonMetrics) => css`
  margin: 0;
  width: ${metrics.width ?? "auto"};
  padding: ${
    metrics.width
      ? `${padding[metrics.size].v} 0`
      : `${padding[metrics.size].v} ${padding[metrics.size].h}`
  };
`;
