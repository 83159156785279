import { Spinner } from "bits/Spinner";
import s from "styled-components";

function Arrow() {
  return (
    <Box right="15px">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Arrow</title>
        <path
          d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9V7ZM15.7071 8.70711C16.0976 8.31658 16.0976 7.68342 15.7071 7.29289L9.34315 0.928932C8.95262 0.538408 8.31946 0.538408 7.92893 0.928932C7.53841 1.31946 7.53841 1.95262 7.92893 2.34315L13.5858 8L7.92893 13.6569C7.53841 14.0474 7.53841 14.6805 7.92893 15.0711C8.31946 15.4616 8.95262 15.4616 9.34315 15.0711L15.7071 8.70711ZM1 9H15V7H1V9Z"
          fill="currentColor"
        />
      </svg>
    </Box>
  );
}

function SpinnerAccessory() {
  return (
    <Box right="10px">
      <Spinner size={22} color="currentColor" />
    </Box>
  );
}

const Box = s.div<{ right: string }>`
position: absolute;
right: ${(props) => props.right};
top: 0;
bottom: 0;

display: flex;
align-items: center;
`;

export const ButtonAccessory = {
  Arrow: <Arrow />,
  Spinner: <SpinnerAccessory />,
};
