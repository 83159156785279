import { useUser, withUser } from "next-firebase-auth";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment, useState } from "react";
import s, { keyframes } from "styled-components";

import { RoundedButton } from "bits/Button";
import Brand from "../brand";
import NavToggle from "../nav-toggle";

interface HeaderProps {
  isHero?: boolean;
  isBrowser?: boolean;
}

const MarketingHeader = ({
  isHero = false,
  isBrowser = false,
}: HeaderProps) => {
  const router = useRouter();

  const [sidebarState, changeSidebarState] = useState("initial");

  const authUser = useUser();
  const isAuthed = !!authUser.id;

  return (
    <Header isHero={isHero} isBrowser={isBrowser}>
      <Content>
        <Primary>
          <Left isHero={isHero}>
            <Brand white={!isHero} />
            <Tagline isHero={isHero}>Personal Sites for Professionals</Tagline>
          </Left>
          <MenuToggle
            color={isHero || isBrowser ? "black" : "white"}
            onClick={() => changeSidebarState("showing")}
          />
          <Nav toggledState={sidebarState}>
            <CloseButton
              opened={sidebarState === "showing"}
              onClick={() => changeSidebarState("hiding")}
            />
            <Link passHref href="/" legacyBehavior>
              <NavLink active={router.pathname === "/home"}>Home</NavLink>
            </Link>
            <Link passHref href="/directories" legacyBehavior>
              <NavLink active={router.pathname === "/home/directories"}>
                Directories
              </NavLink>
            </Link>
            <Link passHref href="/features" legacyBehavior>
              <NavLink active={router.pathname === "/home/features"}>
                Features
              </NavLink>
            </Link>
            <Link passHref href="/pricing" legacyBehavior>
              <NavLink active={router.pathname === "/home/pricing"}>
                Pricing
              </NavLink>
            </Link>
            <Link passHref href="/about" legacyBehavior>
              <NavLink active={router.pathname === "/home/about"}>
                About
              </NavLink>
            </Link>
            {isAuthed ? (
              <RoundedButton
                size="medium"
                width="160px"
                href="/dashboard"
                title="My Dashboard"
              />
            ) : (
              <Fragment>
                <Link passHref href="/login?from=header" legacyBehavior>
                  <LogInLink isHero={isHero}>Log In</LogInLink>
                </Link>
                <RoundedButton
                  size="medium"
                  width="160px"
                  href="/signup?from=header"
                  title="Sign Up"
                />
              </Fragment>
            )}
          </Nav>
        </Primary>
      </Content>
    </Header>
  );
};

export default withUser<HeaderProps>()(MarketingHeader);

const Header = s.header<{ isHero: boolean; isBrowser: boolean }>`
padding: 60px 80px;

border-bottom: ${(props) =>
  !props.isHero && !props.isBrowser
    ? "4px solid var(--color-light-blue)"
    : "none"};
background: ${(props) =>
  props.isHero
    ? "transparent"
    : props.isBrowser
      ? "var(--color-background)"
      : "radial-gradient(75.21% 77.36% at 100% 0%, rgba(0, 132, 255, 0.3) 0%, rgba(0, 132, 255, 0.206636) 54.53%, rgba(0, 132, 255, 0) 100%), radial-gradient(80.1% 156.86% at 0% 0%, #00C2FF 0%, #133364 100%)"};

color: ${(props) => (props.isHero || props.isBrowser ? "black" : "white")};
box-sizing: border-box;

@media (max-width: calc(1400px + 100px)) {
  padding: 40px;
}
`;

const Content = s.div`
display: flex;
justify-content: center;
`;

const Primary = s.div`
position: relative;
display: flex;
align-items: center;
max-width: 1400px;
width: 100%;

@media (max-width: calc(1400px + 100px)) {
  margin: 0;
}

@media (max-width: ${(props) => props.theme.widths.medium}) {
  align-items: flex-start;
  margin: 0;
}
`;

const Left = s.div<{ isHero: boolean }>``;

const MenuToggle = s(NavToggle)`
margin-left: auto;
margin-right: 0;
margin-top: 0;
`;

const revealNavigation = keyframes`
from {
  opacity: 0;
  visibility: visible;
  transform: scale(0.9);
}

to {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
`;

const closeNavigation = keyframes`
from {
  opacity: 1;
  visibility: visible;
}

to {
  opacity: 0;
  visibility: hidden;
  transform: scale(0.9);
}
`;

const Nav = s.nav<{ toggledState: string }>`
display: flex;
align-items: stretch;
margin: auto;
margin-right: 0;

a:last-child {
  margin-left: 1.5rem;
}

@media (max-width: ${(props) => props.theme.widths.medium}) {
  // position
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  z-index: ${(props) => props.theme.z.headerNavigation};

  // display/visibility
  display: flex;
  flex-direction: column;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  max-width: 400px;

  // animation
  transform-origin: 100% 0;
  animation: ${(props) =>
    props.toggledState === "showing"
      ? revealNavigation
      : props.toggledState === "hiding"
        ? closeNavigation
        : ""} 0.2s ease;
  animation-fill-mode: forwards;

  // styling
  border-radius: 8px;
  padding: 1.5rem;
  padding-top: 3rem;
  background: white;
  color: black;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 5%),
    0 2px 4px rgb(0 0 0 / 5%),
    0 4px 8px rgb(0 0 0 / 10%),
    0 8px 16px rgb(0 0 0 / 15%),
    0 16px 32px rgb(0 0 0 / 20%);

  // this is our hacky way of styling the sign up/dashboard button
  a:last-child {
    margin-left: 0;
    margin-top: 10px;
    width: 80%;
    font-size: 20px;
  }
}
`;

const CloseButton = s(NavToggle)`
display: none;

@media (max-width: ${(props) => props.theme.widths.medium}) {
  position: absolute;
  top: 20px;
  right: 20px;

  display: block;
  padding: 0;
  margin: 0;
}
`;

const NavLink = s.a<{ active?: boolean }>`
display: flex;
align-items: center;
margin-left: 1.5rem;
padding: 0.5rem 0;
font-size: 16px;
line-height: 22px;
font-weight: 600;
text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
text-decoration: none;
color: inherit;
border-bottom: ${(props) =>
  props.active ? "1px solid" : "1px solid transparent"};

&:hover {
  border-bottom: 1px solid;
}

&:first-child {
  margin: 0;
}

@media (max-width: ${(props) => props.theme.widths.medium}) {
  margin: 10px 0;
  padding: 10px 0;
  justify-content: center;
  width: 100%;

  font-size: 20px;
  line-height: 27px;
  border: none;

  &:hover {
    background: rgb(0 0 0 / 10%);
    border: none;
    border-radius: 4px;
  }
}
`;

const LogInLink = s(NavLink)<{ isHero: boolean }>`
color: ${(props) => (props.isHero ? "white" : "var(--color-light-blue)")};

@media (max-width: ${(props) => props.theme.widths.medium}) {
  color: ${(props) =>
    props.isHero ? "var(--color-blue)" : "var(--color-light-blue)"};
}
`;

const Tagline = s.h2<{ isHero: boolean }>`
margin: 0;
margin-top: 10px;
font-family: Manrope, sans-serif;
font-weight: 700;
font-size: 18px;
line-height: normal;
color: ${(props) => (props.isHero ? "black" : "white")};

@media (max-width: ${(props) => props.theme.widths.small}) {
  font-size: inherit;
  line-height: inherit;
}
`;
