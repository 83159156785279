import s, { css } from "styled-components";

import { base } from "./shared/base";
import { type ButtonProps, createButton } from "./shared/factory";
import { metrics } from "./shared/metrics";

interface RoundedButtonProps {
  highlight?: boolean;
}

type CombinedRoundedProps = ButtonProps & RoundedButtonProps;

const styles = ({ color = "blue", ...props }: CombinedRoundedProps) => css`
  ${base()}

  // metrics
  ${metrics(props)};

  // text
  font: inherit;
  font-weight: bold;

  // colors
  color: white;
  border: none;
  background: white;
  box-shadow: ${
    props.highlight ? `0 0 0 10px rgba(var(--color-${color}-raw), 0.2)` : "none"
  };

  border-radius: 9999px;
  cursor: pointer;
  transition:
    box-shadow 0.25s ease-in-out,
    color 0.25s ease-in-out;

  &::before {
    content: "";

    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    transition: opacity 0.25s ease-in-out;
    border-radius: 9999px;
    background: linear-gradient(
      to bottom,
      var(--color-light-${color}),
      var(--color-${color})
    );
  }

  &:not([disabled]) {
    &:hover {
      color: var(--color-${color});
      box-shadow:
        0 0 0 2px var(--color-${color}),
        0 3px 8px rgb(0 0 0 / 10%);
    }

    &:active,
    &:focus {
      color: var(--color-${color});
      box-shadow:
        0 0 0 2px var(--color-${color}),
        inset 0 3px 8px rgb(0 0 0 / 10%);
    }

    &:hover::before,
    &:active::before,
    &:focus::before {
      opacity: 0;
    }
  }

  &[disabled] {
    cursor: not-allowed;
  }
`;

const ComponentButton = s.button<{ buttonProps: CombinedRoundedProps }>`
${(props) => styles(props.buttonProps)};
`;

const ComponentLink = s.a<{ buttonProps: CombinedRoundedProps }>`
${(props) => styles(props.buttonProps)};

text-decoration: none;
`;

export const RoundedButton = createButton<RoundedButtonProps>(
  ComponentLink,
  ComponentButton,
);
