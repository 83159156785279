import Link from "next/link";
import type { MouseEventHandler, ReactNode } from "react";
import type { IStyledComponent } from "styled-components";

import type { ButtonMetrics } from "./metrics";

export interface ButtonBaseProps {
  title: string;
  accessory?: ReactNode;
  color?: "blue" | "green";
}

interface ButtonActionLink {
  href: string;
  external?: boolean;
}

interface ButtonActionHandler {
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

type ButtonActions = ButtonActionLink | ButtonActionHandler;

const isActionLink = (props: ButtonActions): props is ButtonActionLink => {
  return "href" in props;
};

export type ButtonProps = ButtonBaseProps & ButtonActions & ButtonMetrics;

export function createButton<AdditionalProps>(
  ComponentLink: IStyledComponent<
    "web",
    "a",
    { buttonProps: ButtonBaseProps & AdditionalProps }
  >,
  ComponentButton: IStyledComponent<
    "web",
    "button",
    { buttonProps: ButtonBaseProps & AdditionalProps }
  >,
) {
  return function ButtonFactory(props: ButtonProps & AdditionalProps) {
    if (isActionLink(props)) {
      if (props.external) {
        return (
          <ComponentLink href={props.href} buttonProps={props}>
            {props.title}
            {props.accessory}
          </ComponentLink>
        );
      } else {
        return (
          <Link href={props.href} passHref legacyBehavior>
            <ComponentLink buttonProps={props}>
              {props.title}
              {props.accessory}
            </ComponentLink>
          </Link>
        );
      }
    } else {
      return (
        <ComponentButton
          onClick={props.onClick}
          buttonProps={props}
          disabled={props.disabled}
        >
          {props.title}
          {props.accessory}
        </ComponentButton>
      );
    }
  };
}
